.toolbox {
	display: flex;
	justify-content: center;
	position: relative;
	background-color: $colorBgAlternative2;
	margin: 0 $mobilePadding;
	width: calc(100% - (#{$mobilePadding} * 2));
	padding: $gridUnit 0;
	z-index: 10;

	@include media($midBreakpoint) {
		background-color: unset;
	}

	@include media($sidebarBreakpoint) {
		margin: 0;
		padding: 0;
		width: 100%;
		margin-top: $gridUnit;
		margin-bottom: $gridRow;
	}

	&__button {
		position: relative;
	}

	.js-coveredByMedia & {
		margin: 0;
		background-color: transparent;
		display: flex;
		justify-content: flex-end;
		width: auto;
		padding: $gridUnit $gridUnit;
		position: fixed;
		top: 0;
		right: 0;
		left: 0;
		z-index: 40;
	}
}
