.quote {
	@include font(quote);
	margin: 0;
	padding-left: $mobilePadding * 2;
	padding-right: $mobilePadding;
	margin-bottom: $gridRow;
	@include media($midBreakpoint) {
		text-align: justify;
		padding-left: gridColumns(2, 11);
		padding-right: gridColumns(2, 11);
		.sidebarredContainer & {
			padding-left: gridColumns(2, 8);
			padding-right: gridColumns(2, 8);
		}
	}
}
