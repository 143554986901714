.logo {
	display: inline-block;
	height: 100%;
	width: auto;


	svg {
		width: auto;
		height: 100%;
	}
}
