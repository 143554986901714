.subheadline {
	@include font(largeBreathing);
	margin-top: $gridRow;
	margin-bottom: $gridUnit;
	padding-left: $mobilePadding;
	padding-right: $mobilePadding;
	@include media($midBreakpoint) {
		padding-left: gridColumns(1, 11);
		padding-right: gridColumns(1, 11);
		.sidebarredContainer & {
			padding-left: gridColumns(1, 8);
			padding-right: gridColumns(1, 8);
		}
	}
}


