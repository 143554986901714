.textContact {
	font-family: $fontFamilyAlt;
	@include font(metaBigger);

	a {
		@include app-link()
	}

	@include media($midBreakpoint) {
		margin-bottom: $gridRow;
	}

	.sidebarInfo & {
		text-align: center;
	}
}
