.sidebarredContainer {
	display: flex;
	width: 100%;
	position: relative;
	flex-direction: column;
	&__main {
		@include media($sidebarBreakpoint) {
			background-color: $colorBgContent;
			width: gridColumns(8, 11);
		}
	}
	&__sidebar {
		position: relative;
		font-family: $fontFamilyAlt;
		@include font(metaBigger);
		@include media($sidebarBreakpoint) {
			background-color: $colorBgSidebar;
			width: gridColumns(3, 11);
		}
	}
	&__sidebarContent {
		position: absolute;
	}
	@include media($sidebarBreakpoint) {
		flex-direction: row;
	}
}
