.page {
	display: flex;
	flex-direction: column;
	width: 100%;
	position: relative;

	&__title {
		@include font(title);
		margin-bottom: $gridRow;
	}

	&--home {
		@include media($midBreakpoint) {
			width: calc(100% - #{$mobilePadding});
		}
		@include media($largeBreakpoint) {
			width: gridColumns(9, 11);
		}
	}

	@include media($midBreakpoint) {
		background-color: $colorBgContent;
		&--home {
			background-color: transparent;
		}
	}
}
