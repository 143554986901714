$htmlFontSize: 62.5%;

$fontSizes: (
	base: (
		font-size: pxToRem(18),
		line-height: 1.5,
	),
	title: (
		font-size: pxToRem(35),
		line-height: 1.2,
		font-weight: normal,
		variants: (
			$midBreakpoint: (
				font-size: pxToRem(48),
			)
		)
	),
	large: (
		font-size: pxToRem(24),
		line-height: 1.4,
		font-weight: normal,
	),
	largeBreathing: (
		font-size: pxToRem(24),
		line-height: 1.6,
		font-weight: normal
	),
	metaBase: (
		font-size: pxToRem(12)
	),
	metaBigger: (
		font-size: pxToRem(14),
		line-height: 2, // really?
		font-weight: normal
	),
	quote: (
		font-size: pxToRem(18),
		line-height: 1.8,
		font-weight: normal,
		variants: (
			$midBreakpoint: (
				line-height: 1.6,
			)
		)
	),
	small: (
		font-size: pxToRem(10),
		line-height: 1.2,
	),

	// generic/predefined …
	baseBigger: (
		font-size: pxToRem(20),
		line-height: 1.6
	),
	titleBigger: (
		font-size: pxToRem(26),
		line-height: 1.6
	),
	subtitle: (
		font-size: pxToRem(18),
		line-height: 1.6,
		font-weight: bold
	),
	subtitleBigger: (
		font-size: pxToRem(22),
		line-height: 1.6
	),
	caption: (
		font-size: pxToRem(12),
		line-height: 1.6
	),
	fieldDescription: (
		font-size: pxToRem(16),
		line-height: 1.6
	),
	fieldError: (
		font-size: pxToRem(16),
		line-height: 1.6
	),
	googleMapPopup: (
		font-size: pxToRem(16),
		line-height: 1.6
	)
);
