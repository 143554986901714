.pageContainer {

	.js-coveredByMenu & {
		@include app-covered;
		display: none;
	}

	@include media($midBreakpoint) {
		width: gridColumns(11, 14);
	}

	@include media($largeBreakpoint) {
	}

}
