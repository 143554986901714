.event {
	width: 100%;
	margin-top: $gridUnit;
	border-top: solid 1px black;
	padding-top: $gridUnit;
	padding-bottom: $gridUnit * 2;

	@include media($midBreakpoint) {
		border-color: transparentize($colorBase, 0.8);
		padding-bottom: 0;
	}

	&__link {
		display: flex;
		flex-direction: column;
		@include app-link();
		@include media($midBreakpoint) {
			flex-direction: row;
		}
	}

	&__meta {
		font-family: $fontFamilyAlt;
		@include font(metaBigger);
		@include media($midBreakpoint) {
			width: gridColumns(1.5, 8);
			padding-right: $gridUnit * 2;
		}
	}

	&__title {
		font-family: $fontFamilyBold;

		.event__text--alternative & {
			color: $colorNotActive;
		}

		&::after {
			content: ' •';
			color: $colorHighlight;
		}

		&--alternative::after {
			content: '';
		}
	}

	&__text {
		margin-top: $gridUnit;
		border-top: solid 1px transparentize($colorBase, 0.8);
		padding-top: $gridUnit;

		@include media($midBreakpoint) {
			margin-top: 0;
			border-top: none;
			padding-top: 0;
			width: gridColumns(4.5, 8);
			padding-right: $gridUnit * 2;
		}
	}

	&__image {
		display: none;
		@include media($midBreakpoint) {
			display: block;
			width: gridColumns(2, 8);
			img {
				max-width: 100%;
			}
		}
	}
}
