$ctaBreakpoint: $sidebarBreakpoint;

.calltoaction {
	width: 100%;
	display: flex;
	flex-direction: column;
	padding-left: $mobilePadding;
	padding-right: $mobilePadding;
	text-align: center;
	@include media($ctaBreakpoint) {
		text-align: left;
		flex-direction: row;
		padding: 0;
	}

	&__main {
		display: flex;
		flex-direction: column;
		align-items: center;
		background-color: $colorHighlight;
		color: white;
		padding-left: $mobilePadding;
		padding-right: $mobilePadding;
		padding-top: $gridUnit * 2;
		padding-bottom: $gridUnit * 2;
		height: 100%;
		@include media($ctaBreakpoint) {
			align-items: unset;
			border-bottom: $gridRow solid $colorBgContent;
			padding-top: $gridRow;
			padding-bottom: $gridRow;
			padding-left: gridColumns(1, 8);
			padding-right: gridColumns(1, 8);
		}
	}


	&__questions {
		order: 2;

		&:after {
			// the separator line for mobile
			content: ' ';
			margin: ($gridUnit * 2) auto 0 auto;
			display: block;
			width: 1px;
			height: $gridRow * 3;
			border-left: solid 1px white;
		}

		@include media($ctaBreakpoint) {
			order: 1;
			width: gridColumns(4, 5);
			&:after {
				display: none;
			}
		}
	}

	&__invitation {
		order: 3;

		&:before {
			// the white dot for mobile
			@include app-dot(white);
			position: relative;
			top: 0 - ($gridUnit * 0.5);
			left: -1px;
			margin-bottom: $gridUnit * 2;
			@include media($ctaBreakpoint) {
				display: none;
			}
		}

		@include media($ctaBreakpoint) {
			width: gridColumns(4, 5);
		}
	}


	&__logoLine {
		display: flex;
		position: relative;
		order: 1;
		@include media($ctaBreakpoint) {
			order: 2;
		}
	}

	&__logo {
		margin-bottom: $gridUnit * 2;

		[data-type="svg"] {
			width: $gridUnit * 8;
		}

		@include media($ctaBreakpoint) {
			margin-bottom: 0;
			position: relative;
			width: $gridUnit * 8;
			margin-left: $gridUnit;
			[data-type="svg"] {
				position: absolute;
				top: 0 - ($gridUnit * 2);
				right: gridColumns(-0.5, 8);
				width: $gridUnit * 8;
			}
		}
	}


	&__line {
		@include media($ctaBreakpoint) {
			padding-top: $gridUnit * 2;
			margin-top: $gridUnit * 2;
			border-top: 1px solid white;
			position: relative;
			width: gridColumns(5.25, 6);
			&:before {
				@include app-dot(white);
				left: 0 - ($gridUnit * 3);
				top: 0 - ($gridUnit * 0.5);
			}
		}
	}


	&__contact {
		padding: $gridRow * 0.5 $mobilePadding;
		text-align: center;
		@include media($ctaBreakpoint) {
			padding: ($gridUnit * 2) gridColumns(0.5, 3) $gridRow gridColumns(0.5, 3);
			border-top: 1px solid $colorHighlight;
		}
	}

	&__contactTitle {
		@include meta-header;
		text-align: center;
		margin-bottom: $gridUnit;
		hyphens: auto;
	}
	& .ugc a {
		color: $colorBgBase
	}
}
