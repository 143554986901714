.sidebarInfo {
	@include font(metaBigger);
	font-family: $fontFamilyAlt;
	line-height: 1.6;
	margin-top: $gridRow; // fixme
	margin-bottom: $gridRow;
	padding: 0 $mobilePadding;
	@include media($sidebarBreakpoint) {
		padding: 0 gridColumns(1, 6);
	}
	.blocks & {
		// padding removed inside blocks
		@include media($sidebarBreakpoint) {
			padding: 0;
		}
	}

	&__title {
		@include meta-header;
		text-align: center;
		margin-bottom: $gridUnit;
	}
}
