.image {
	&__img {
		max-width: 100%;
		width: 100%;
		@include media($midBreakpoint) {
			width: auto;
		}
	}

	// image block in main content
	.sidebarredContainer__main & {
		padding-bottom: $gridRow;

		padding-left: $mobilePadding;
		padding-right: $mobilePadding;

		@include media($midBreakpoint) {
			padding-left: gridColumns(1, 8);
			padding-right: 0;
		}

		&__img {
			width: 100%;
		}

		&__caption {
			@include font(metaBigger);
			font-family: $fontFamilyAlt;
			padding-right: gridColumns(1, 7);
			margin-top: $gridUnit;
		}

		&__captionContent {
			display: inline;

			p {
				display: inline;
			}
		}

		&__credits {
			display: inline;
		}
	}

	// image block inside “gallery teaser” block
	.block--galleryTeaser & {
		padding: 0;

		&__img {
			width: 100%;
		}

		&__caption {
			@include font(metaBigger);
			font-family: $fontFamilyAlt;
			padding-right: gridColumns(1, 7);
			margin-top: $gridUnit;
		}

		&__captionContent {
			display: inline;

			p {
				display: inline;
			}
		}

		&__credits {
			display: inline;
		}
	}

	// image block inside slideshow block ergo modal
	.slideshow & {
		.img {
			padding-top: $gridUnit*4 + $gridRow;
		}

		&__img {
			// width: 100%;
			min-width: 100%;
			min-height: 100%;
			max-width: 100vw;
		}

		&__caption {
			padding: $gridUnit*2 $gridUnit $gridUnit*2 $gridUnit;
			position: fixed;
			bottom: 0;
			left: 0;
			right: 0;
			background-color: $colorBgOverlay;
			display: flex;
			justify-content: space-between;
			flex-direction: column;
			@include media($midBreakpoint) {
				flex-direction: row;
			}
		}

		&__captionContent {
			text-align: justify;
			width: gridColumns(12);
			margin-left: 0;
			@include media($midBreakpoint) {
				width: gridColumns(6);
			}
		}

		&__credits {
			@include font(metaBigger);
			font-family: $fontFamilyAlt;
			width: gridColumns(12);
			@include media($midBreakpoint) {
				text-align: right;
				width: gridColumns(3);
			}
		}
	}

	// image block in the sidebar
	.sidebar & {
		padding-left: $mobilePadding;
		padding-right: $mobilePadding;
		@include media($midBreakpoint) {
			padding-left: 0;
			padding-right: 0;
		}

		&__caption {
			margin-top: $gridUnit;
			font-family: $fontFamilyAlt;
			@include font(metaBigger);
		}

		margin-bottom: $gridUnit;

	}
}
