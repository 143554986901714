.infoSection {
	width: 100%;
	display: flex;
	align-items: first baseline;
	&__icon {
		a {
			color: inherit;
		}
		margin-left: auto;
		padding-left: $gridUnit;
		position: relative;
		top: 3px;

	}

	.sidebarInfo & {
		padding-bottom: $gridUnit * 0.5;
	}

	.collage & {
		&__icon {
			color: white;
		}
	}
}
