.projectGrid {
	display: flex;
	flex-direction: column;
	$gutter: 15px;
	@include media($midBreakpoint) {
		flex-direction: row;
		flex-wrap: wrap;
	}

	@mixin cellLayout($numCols) {
		width: calc((100% - (#{$gutter} * #{$numCols - 1})) / #{$numCols});
		&:nth-child(#{$numCols}n) {
			margin-right: 0;
		}
	}

	&__cell {
		position: relative;
		width: 100%;
		margin-bottom: $gridUnit;
		@include media($midBreakpoint) {
			margin-right: $gutter;
			@include cellLayout(2);
		}
		@include media($sidebarBreakpoint) {
			@include cellLayout(3);
		}
		@include media($largeBreakpoint) {
			@include cellLayout(4);
		}
		.js-masonryOn & {
			margin-right: 0;
		}
	}
}
