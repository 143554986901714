.mainMenu {
	@include font(metaBigger);
	font-family: $fontFamilyAlt;
	line-height: 1.4;
	position: relative;
	z-index: 60;
	display: none;
	width: 100%;
	padding: 0 ($mobilePadding * 2);

	.js-coveredByMedia & {
		z-index: 0;
		@include app-covered();
	}

	.js-coveredByMenu & {
		display: block;
		position: fixed;
	}

	@include media($midBreakpoint) {
		display: block;
		padding-right: $gridUnit * 2;
		width: gridColumns(3);
	}

	@include media($largeBreakpoint) {
		width: gridColumns(2.5);
		margin-left: gridColumns(0.5);
	}
}
